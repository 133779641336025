@use 'src/src/app/scss/abstracts' as *;

.video {
  $block-name: &;

  @include reset-text-indents;

  &__inner {
    position: relative;
    z-index: 0;
    overflow: hidden;

    video {
      @include position(absolute, 0 0 0 0);

      display: block;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity $transition-fast;
      pointer-events: none;
      object-fit: cover;
    }

    .image {
      @include position(absolute, 0 0 0 0);

      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  &--loaded {
    #{$block-name} {
      &__inner {
        background: none;

        video {
          opacity: 1;
        }
      }
    }
  }
}
